<template>
  <div>
    <div class="entry">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3 class="uk-text-background">社員情報追加・修正</h3>

      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <div
          class="
            uk-container
            uk-flex
            uk-flex-middle
            uk-text-left
            uk-padding-remove-left
            uk-margin
          "
        >
          <div class="">
            <label
              class="uk-button uk-button-default"
              style="background-color: rgb(50, 210, 150); color: snow"
              @click="get_info_employee(1)"
            >
              Excelファイルで出力する
            </label>
          </div>

          <div class="uk-margin-left">
            <label
              class="uk-button uk-button-primary"
              style="background-color: rgb(50, 210, 150); color: snow"
            >
              Excelファイルを読み込む<input
                type="file"
                id="load_excel"
                @change="set_data"
                style="display: none"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </label>
          </div>

          <div class="uk-margin-left">
            <input
              type="button"
              id="upload_excel"
              name=""
              class="uk-button uk-button-primary"
              v-if="uploadfile"
              @click="send_file"
              value="ファイル送信"
            />
          </div>
        </div>

        <div
          v-if="isBusyoRegistered"
          class="memadd uk-padding-small"
          style="border: 1px solid gainsboro; border-radius: 10px"
        >
          <div
            v-if="data != null"
            class="
              uk-container uk-flex uk-flex-middle uk-flex-center uk-margin-small
            "
          >
            <div class="uk-width-1-3 uk-text-right">
              <button
                type="button"
                name=""
                class="uk-button uk-button-text"
                @click="prev_page"
                v-if="paging > 0"
              >
                &lt;&nbsp;前の{{ number_of_items }}件へ
              </button>
            </div>
            <div class="uk-width-1-3">
              <select
                class="uk-select uk-form-width-xsmall"
                name="number_of_items"
                v-model="number_of_items"
                @change="paging = 0"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option></select
              ><label for="number_of_items">件表示</label>
            </div>
            <div class="uk-width-1-3 uk-text-left">
              <button
                type="button"
                class="uk-button uk-button-text"
                name=""
                @click="next_page"
                v-if="(paging + 1) * number_of_items < data.length"
              >
                次の{{ number_of_items }}件へ&nbsp;&gt;
              </button>
            </div>
          </div>
          <hr class="uk-margin-small" />
          <div
            class="uk-container uk-form-label uk-margin uk-padding-remove-left"
          >
            <div class="uk-width-1-1 uk-text-left uk-padding-remove-vertical">
              <span class="uk-text-danger">※</span>
              は必須項目です
            </div>
          </div>

          <div class="uk-overflow-auto" v-if="data != null">
            <table
              class="uk-table uk-table-small uk-table-divider uk-table-hover"
            >
              <tr>
                <th></th>
                <th>社員番号&nbsp;<sup>※</sup></th>
                <th>氏名&nbsp;<sup>※</sup></th>
                <th>フリガナ&nbsp;<sup>※</sup></th>
                <th>生年月日&nbsp;<sup>※</sup></th>
                <th>性別&nbsp;<sup>※</sup></th>
                <th>所属部署&nbsp;<sup>※</sup></th>
                <th>メールアドレス</th>
                <th>電話番号</th>
              </tr>
              <tr
                v-for="(d, index) in data.slice(
                  number_of_items * paging,
                  number_of_items * (paging + 1)
                )"
                v-bind:key="d"
              >
                <template
                  v-if="
                    !d.deleted_flg &&
                    index + number_of_items * paging < data.length
                  "
                >
                  <td>
                    <span
                      uk-icon="icon: trash"
                      class="uk-icon uk-text-danger"
                      @click="set_deleted_flg(index + number_of_items * paging)"
                    ></span>
                  </td>
                  <td style="min-width: 100px">
                    <input
                      type="text"
                      :class="
                        check_undefined(index + number_of_items * paging, 0)
                      "
                      class="uk-input uk-form-small"
                      v-model="d.emp_code"
                    />
                  </td>
                  <td style="min-width: 150px">
                    <input
                      type="text"
                      :class="
                        check_undefined(index + number_of_items * paging, 1)
                      "
                      class="uk-input uk-form-small"
                      v-model="d.name"
                    />
                  </td>
                  <td style="min-width: 250px">
                    <input
                      type="text"
                      :class="
                        check_undefined(index + number_of_items * paging, 2)
                      "
                      class="uk-input uk-form-small"
                      v-model="d.furigana"
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      :class="
                        check_undefined(index + number_of_items * paging, 3)
                      "
                      class="uk-input uk-form-small"
                      v-model="d.birth_date"
                      onkeydown="return false"
                    />
                  </td>
                  <td>
                    <select
                      v-model="d.sex"
                      :class="
                        check_undefined(index + number_of_items * paging, 4)
                      "
                      class="uk-select uk-form-small"
                    >
                      <option value="男">男</option>
                      <option value="女">女</option>
                    </select>
                  </td>
                  <td style="min-width: 150px">
                    <select
                      v-model="d.workplace_name"
                      :class="
                        check_undefined(index + number_of_items * paging, 5)
                      "
                      class="uk-select uk-form-small"
                    >
                      <template v-for="li in busyo_list" :key="li.busyo_name">
                        <option :value="li.busyo_name">
                          {{ li.busyo_name }}
                        </option>
                      </template>
                    </select>
                  </td>
                  <td style="min-width: 250px">
                    <input
                      type="text"
                      v-model="d.email"
                      :class="
                        check_undefined(index + number_of_items * paging, 6)
                      "
                      class="uk-input uk-form-small"
                    />
                  </td>
                  <td style="min-width: 130px">
                    <input
                      type="text"
                      v-model="d.telephone_number"
                      :class="
                        check_undefined(index + number_of_items * paging, 7)
                      "
                      class="uk-input uk-form-small"
                    />
                  </td>
                </template>
              </tr>
            </table>
            <div class="uk-width-1-1 uk-margin">
              <div>
                <span uk-icon="icon: plus-circle" @click="add_data"
                  >入力欄追加&nbsp;</span
                >
              </div>
            </div>
          </div>

          <div
            v-if="data != null"
            class="
              uk-container uk-flex uk-flex-middle uk-flex-center uk-margin-top
            "
          >
            <div class="uk-width-1-3 uk-text-right">
              <button
                type="button"
                name=""
                class="uk-button uk-button-text"
                @click="prev_page"
                v-if="paging > 0"
              >
                &lt;&nbsp;前の{{ number_of_items }}件へ
              </button>
            </div>
            <div class="uk-width-1-3">
              <select
                class="uk-select uk-form-width-xsmall"
                name="number_of_items"
                v-model="number_of_items"
                @change="paging = 0"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option></select
              ><label for="number_of_items">件表示</label>
            </div>
            <div class="uk-width-1-3 uk-text-left">
              <button
                type="button"
                class="uk-button uk-button-text"
                name=""
                @click="next_page"
                v-if="(paging + 1) * number_of_items < data.length"
              >
                次の{{ number_of_items }}件へ&nbsp;&gt;
              </button>
            </div>
          </div>
        </div>

        <div class="uk-container uk-flex uk-flex-center uk-flex-middle">
          <div class="uk-width-1-2 uk-padding-small">
            <button
              type="button"
              name="button"
              @click="$router.back()"
              class="uk-button uk-button-default uk-width-4-5"
            >
              &lt;&nbsp;戻る
            </button>
          </div>
          <div v-if="isBusyoRegistered" class="uk-width-1-2 uk-padding-small">
            <button
              type="button"
              name="button"
              @click="send_data"
              class="uk-button uk-button-primary uk-width-4-5 mal10"
            >
              従業員情報登録
            </button>
          </div>
        </div>

        <br />
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      status: false,
      data: [],
      busyo_list: "",
      uploadfile: "",
      number_of_items: 10,
      paging: 0,
      error_check: {},
      do_something: true,
      isBusyoRegistered: false, // 部署登録済み判定
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "従業員情報";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.status = this.$store.state.login_status_c;
    this.checkLogined();
    if (this.status) {
      this.get_info_busyo();
      this.get_info_employee();
    }
  },
  computed: {
    check_undefined() {
      return (i, j) => {
        if (this.error_check[i][j]) {
          return "error";
        } else {
          return "";
        }
      };
    },
  },
  methods: {
    checkLogined() {
      if (!this.status) {
        this.$router.replace("/client-login");
      }
    },
    prev_page() {
      this.paging -= 1;
    },
    next_page() {
      this.paging += 1;
    },
    set_data(e) {
      this.uploadfile = e.target.files[0];
      console.log(this.uploadfile);
    },
    send_file() {
      if (!this.do_something) return;
      this.do_something = false;
      const params = new FormData();
      params.append("file", this.uploadfile);
      params.append("email", this.$store.state.email);
      params.append("pw", this.$store.state.pass);
      params.append("alias_id", this.$store.state.alias_id);
      this.$axios
        .post("/client/upload_file", params, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then(
          function (response) {
            console.log(response);
            if (response.data.flg) {
              alert("更新が完了しました");
              if (response.data.empty_col.length != 0) {
                alert(
                  response.data.empty_col +
                    "行目の必須項目が空のため登録できませんでした\n一度確認して再度登録してください"
                );
              }
              if (response.data.error_row.length != 0) {
                alert(
                  response.data.error_row +
                    "行目の入力データの形式が正しくないため登録できませんでした\n一度確認して再度登録してください"
                );
              }
              this.uploadfile = "";
              this.get_info_busyo();
              this.get_info_employee();
            } else {
              alert("更新に失敗しました");
              if (response.data.status == 4) {
                alert("入力データの形式が不正です");
              }
            }
            this.do_something = true;
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
            this.do_something = true;
          }.bind(this)
        );
    },
    get_info_employee(mode = 0) {
      if (mode == 0) {
        this.$axios
          .post("/client/get_info_employee", {
            alias_id: this.$store.state.alias_id,
            email: this.$store.state.email,
            pw: this.$store.state.pass,
            mode: mode,
          })
          .then(
            function (response) {
              console.log(response);
              if (response.data.flg) {
                this.data = response.data.result;
                console.log(this.data);
                if (this.data.length == 0) {
                  this.add_data();
                }
                this.error_check = this.generate2DArray1(
                  this.data.length,
                  8,
                  false
                );
              } else {
                if (response.data.status == 4) {
                  // this.add_data();
                  console.log("データ未登録");
                }
              }
              this.$store.state.common.sDataLoading = false;
            }.bind(this)
          )
          .catch(
            function (error) {
              console.log(error);
              this.$store.state.common.sDataLoading = false;
            }.bind(this)
          );
      } else if (mode == 1) {
        this.$axios({
          method: "POST",
          url: "/client/get_info_employee",
          data: {
            alias_id: this.$store.state.alias_id,
            email: this.$store.state.email,
            pw: this.$store.state.pass,
            mode: mode,
          },
          responseType: "blob",
          dataType: "binary",
        })
          .then(
            function (response) {
              console.log(response);
              this.downloadCsvBlob(response.data, "従業員データ.xlsx");
              this.error_check = this.generate2DArray1(
                this.data.length,
                8,
                false
              );
              this.$store.state.common.sDataLoading = false;
            }.bind(this)
          )
          .catch(
            function (error) {
              console.log(error);
              this.error_check = this.generate2DArray1(
                this.data.length,
                8,
                false
              );
              this.$store.state.common.sDataLoading = false;
            }.bind(this)
          );
      }
    },
    add_data() {
      this.paging = Math.floor(this.data.length / this.number_of_items);
      this.data.push({
        emp_code: "",
        name: "",
        furigana: "",
        birth_date: "2000-01-01",
        sex: "",
        workplace_name: "",
        email: "",
        telephone_number: "",
        deleted_flg: false,
      });
      this.error_check = this.generate2DArray1(this.data.length, 8, false);
      if (this.data.length % this.number_of_items == 1) {
        this.paging = Math.floor(this.data.length / this.number_of_items);
      }
    },
    send_data() {
      if (!window.confirm("情報を追加・修正してよろしいですか？")) return;
      if (!this.do_something) return;
      this.do_something = false;
      let flg = true;
      this.error_check = this.generate2DArray1(this.data.length, 8, false);
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].deleted_flg != true) {
          if (this.data[i]["emp_code"] == "") {
            this.error_check[i][0] = true;
            flg = false;
          }
          if (this.data[i]["name"] == "") {
            this.error_check[i][1] = true;
            flg = false;
          }
          if (this.data[i]["furigana"] == "") {
            this.error_check[i][2] = true;
            flg = false;
          }
          if (this.data[i]["birth_date"] == "") {
            this.error_check[i][3] = true;
            flg = false;
          }
          if (this.data[i]["sex"] == "") {
            this.error_check[i][4] = true;
            flg = false;
          }
          if (this.data[i]["workplace_name"] == "") {
            this.error_check[i][5] = true;
            flg = false;
          }
          this.do_something = true;
        }
      }
      if (flg == false) {
        alert("必須項目を入力してください");
        return;
      }
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].deleted_flg != true) {
          if (this.data[i].emp_code) {
            if (this.data[i].emp_code.length > 20) {
              this.error_check[i][0] = true;
              flg = false;
            }
          }
          if (this.data[i].name) {
            if (this.data[i].name.length > 30) {
              this.error_check[i][1] = true;
              flg = false;
            }
          }
          if (this.data[i].furigana) {
            if (this.data[i].furigana.length > 50) {
              this.error_check[i][2] = true;
              flg = false;
            }
          }
          if (this.data[i].birth_date) {
            if (this.data[i].birth_date.length > 20) {
              this.error_check[i][3] = true;
              flg = false;
            }
          }
          if (this.data[i].sex) {
            if (this.data[i].sex.length > 20) {
              this.error_check[i][4] = true;
              flg = false;
            }
            if (this.data[i].sex == "") {
              this.error_check[i][4] = true;
              flg = false;
            }
          }
          if (this.data[i].workplace_name) {
            if (this.data[i].workplace_name.length > 40) {
              this.error_check[i][5] = true;
              flg = false;
            }
          }
          if (this.data[i].email) {
            if (this.data[i].email.length > 100) {
              this.error_check[i][6] = true;
              flg = false;
            }
          }
          if (this.data[i].telephone_number) {
            if (this.data[i].telephone_number.length > 16) {
              this.error_check[i][7] = true;
              flg = false;
            }
          }
        }
      }
      console.log(this.error_check);
      if (flg) {
        this.$axios
          .post("/client/update_info_employee", {
            alias_id: this.$store.state.alias_id,
            email: this.$store.state.email,
            pw: this.$store.state.pass,
            info_employee: this.data,
          })
          .then(
            function (response) {
              if (response.data.flg) {
                alert("従業員情報を更新しました");
                this.get_info_employee();
              } else {
                if (response.data.status == 4) {
                  alert("必須項目を入力してください");
                }
              }
              this.do_something = true;
            }.bind(this)
          )
          .catch(
            function (error) {
              console.log(error);
              this.do_something = true;
            }.bind(this)
          );
      } else {
        alert("文字数が多すぎます");
      }
    },
    get_info_busyo() {
      this.$axios
        .post("/client/get_info_busyo", {
          alias_id: this.$store.state.alias_id,
          email: this.$store.state.email,
          pw: this.$store.state.pass,
        })
        .then(
          function (response) {
            console.log(response);
            if (response.data.flg) {
              this.busyo_list = response.data.busyo;
              if (response.data.busyo.length > 0) {
                this.isBusyoRegistered = true;
              }
            }
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
          }.bind(this)
        );
    },
    downloadCsvBlob(blob, fileName) {
      if (window.navigator.msSaveOrOpenBlob) {
        // for IE,Edge
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // for chrome, firefox
        const url = URL.createObjectURL(
          new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          })
        );
        const linkEl = document.createElement("a");
        linkEl.href = url;
        linkEl.setAttribute("download", fileName);
        document.body.appendChild(linkEl);
        linkEl.click();

        URL.revokeObjectURL(url);
        linkEl.parentNode.removeChild(linkEl);
      }
    },
    set_deleted_flg(index) {
      // console.log(index)
      // console.log(this.data[index])
      let result = window.confirm("削除してよろしいですか？");
      if (result == true) {
        this.$axios
          .post("/client/delete_employee", {
            alias_id: this.$store.state.alias_id,
            emp_code: this.data[index].emp_code,
            email: this.$store.state.email,
            pass: this.$store.state.pass,
          })
          .then((response) => {
            if (response.data.flg) {
              alert("削除しました");
              this.get_info_employee();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    generate2DArray1(m, n, val = 0) {
      return Array.from(new Array(m), (_) => new Array(n).fill(val)); // eslint-disable-line no-unused-vars
    },
  },
  watch: {
    "$store.state.login_status_c"(newVal) {
      //eslint-disable-line
      if (newVal == false) {
        this.$router.replace("/client-login");
      }
    },
  },
};
</script>

<style scoped>
.error {
  background: #ff006a3d;
}
</style>
